import { useEffect, useState } from "react";
import logo from "../../assets/logo-without-name.png";
import whatsappMobile from "../../assets/whatsapp-icon-mobile.svg";
import whatsappLogo from "../../assets/whatsapp-icon.svg";
import ProdutctsPage from "../ProductsPage";
import "./styles.css";

function Home() {
  const [productPageOn, setProductPageOn] = useState(false);
  const [backToTop, setBackToTop] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        if (window.scrollY > 100) {
          setBackToTop(!backToTop);
        } else {
          setBackToTop(false);
        }
      },
      [],
    );
  });

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="home">
      <header>
        <div className="logo-section">
          <img src={logo} alt="logo" />
          <div>
            <h1>RONALDO</h1>
            <h3>PRODUTOS GRÁFICOS</h3>
          </div>
        </div>
        {!productPageOn && (
          <nav className={`${!productPageOn ? "menu" : "hidden"}`}>
            <ul>
              <a href="#produtos">
                <li>
                  <button className="address-button" type="button">
                    Produtos
                  </button>
                </li>
              </a>
              <a href="#onde-me-encontrar">
                <li>
                  <button className="address-button" type="button">
                    Endereço
                  </button>
                </li>
              </a>
              <a href="#quem-somos">
                <li className="quem-somos-li">
                  <button className="address-button" type="button">
                    Quem somos
                  </button>
                </li>
              </a>
              <a href="#contato">
                <li>
                  <button className="address-button" type="button">
                    Contato
                  </button>
                </li>
              </a>
            </ul>
          </nav>
        )}
      </header>
      {!productPageOn ? (
        <main>
          <section id="produtos">
            <h1>Produtos</h1>
            <div className="card-container">
              <div className="card plate">
                <h3>Chapas offset</h3>
              </div>
              <div className="card paints">
                <h3>Tintas</h3>
              </div>
              <div className="card chemicals">
                <h3>Químicos</h3>
              </div>
              <div className="card paper">
                <h3>Papel vegetal</h3>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setProductPageOn(!productPageOn);
                scrollUp();
              }}
            >
              Ver mais produtos!
            </button>
          </section>
          <section id="onde-me-encontrar">
            <h1>Onde nos encontrar</h1>
            <div className="maps">
              <iframe
                className="simple-map"
                title="maps"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3065.7148929372743!2d-43.93467043439338!3d-19.856906749867015!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x131c5176c23c79b0!2sRonaldo%20Produtos%20Gr%C3%A1ficos!5e0!3m2!1spt-BR!2sbr!4v1657214429085!5m2!1spt-BR!2sbr"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
              <iframe
                className="street-view"
                title="street-view"
                src="https://www.google.com/maps/embed?pb=!4v1657215960719!6m8!1m7!1sCE50VqDVl41hUkKyaOnCfA!2m2!1d-19.85680845313279!2d-43.93432722833629!3f192.4024791651754!4f-15.927979192997938!5f0.7820865974627469"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </section>
          <section id="quem-somos">
            <div>
              <h1>Quem somos</h1>
              <p>
                Com mais de 30 anos de experiência no mercado de comunicação
                gráfica. Atuamos de maneira concreta e eficiente na solução das
                mais variadas demandas que o mercado exige atualmente, já
                consolidado no mercado mineiro como uma referência no setor.
                Queremos trazer a cada dia nosso melhor atendimento e sempre
                trabalhando com as melhores marcas do mercado. Alguns de nossos
                clientes são editoras, gráficas, jornais e também pequenos e
                médios empreendedores.
              </p>
            </div>
          </section>
        </main>
      ) : (
        <ProdutctsPage
          setProductPageOn={setProductPageOn}
          productPageOn={productPageOn}
        />
      )}
      <footer id="contato">
        <div>
          <h3>Endereço</h3>
          <span>Rua: jane eyre 1373 Loja B</span>
          <span>Bairro: Primeiro de maio</span>
          <span>Belo Horizonte-MG</span>
        </div>
        <div>
          <h3>Contato</h3>
          <strong>Telefone:</strong>
          <a href="tel:3134367579">(31) 3436-7579</a>
          <a href="https://api.whatsapp.com/send?1=pt_BR&phone=553134367579">
            <em>WhatsApp</em>
          </a>
          <strong>E-mail:</strong>
          <a href="mailto:ronaldoprodutosgraf@gmail.com">
            ronaldoprodutosgraf@gmail.com
          </a>
        </div>
      </footer>
      <div className="whatsapp-link">
        <a
          href="https://api.whatsapp.com/send?1=pt_BR&phone=553134367579"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsappLogo} alt="whatsapp logo" id="pc-logo" />
          <img src={whatsappMobile} alt="whatsapp logo" id="mobile-logo" />
        </a>
      </div>
    </div>
  );
}

export default Home;
