import backIcon from "../../assets/back-icon.svg";
import imagemAcidoFosforico from "../../assets/more-products/acido-fosforico.jpeg";
import imagemAlgodao from "../../assets/more-products/algodao.jpeg";
import imagemPoAntiMaculador from "../../assets/more-products/antimaculador.jpeg";
import imagemArame from "../../assets/more-products/arame.jpeg";
import imagemBlanquetas from "../../assets/more-products/blanquetas.jpeg";
import imagemChapaCTP from "../../assets/more-products/chapas-termicas.jpeg";
import imagemChapas from "../../assets/more-products/chapas.jpeg";
import imagemColaBlocagem from "../../assets/more-products/cola.jpeg";
import imagemCorretorDeChapa from "../../assets/more-products/corretor.jpeg";
import imagemEnegrecedor from "../../assets/more-products/enegrecedor.jpeg";
import imagemEsponja from "../../assets/more-products/esponja.jpeg";
import imagemEstopa from "../../assets/more-products/estopa.jpeg";
import imagemFilmeFotolito from "../../assets/more-products/filme-fotolito.jpg";
import imagemGomaSintetica from "../../assets/more-products/goma.jpeg";
import imagemGrampos from "../../assets/more-products/grampos.jpeg";
import imagemLaserFilme from "../../assets/more-products/laserfilme.jpeg";
import imagemLavador from "../../assets/more-products/lavador.jpeg";
import imagemLimpadorDeChapa from "../../assets/more-products/limpador.jpeg";
import imagemMoletom from "../../assets/more-products/moletom.jpeg";
import imagemPralim from "../../assets/more-products/pralim.jpeg";
import imagemReguaDeGuilhotina from "../../assets/more-products/reguas.jpeg";
import imagemRestaurador from "../../assets/more-products/restaurador.jpeg";
import imagemReveladorEmPó from "../../assets/more-products/revelador.jpeg";
import imagemSolucaoDeFonte from "../../assets/more-products/solucao.jpeg";

import "./styles.css";

function ProdutctsPage({ setProductPageOn, productPageOn }) {
  return (
    <main className="products-container">
      <button type="button" className="back-to-home" onClick={() => setProductPageOn(!productPageOn)}>
        <img src={backIcon} alt="back icon" />
      </button>
      <section className="chemicals-container">
        <h2>Químicos</h2>
        <div className="mini-card-container">
          <div>
            <h5>Restaurador</h5>
            <div className="mini-card">
              <img
                src={imagemRestaurador}
                alt="imagem de restaurador de blanquetas"
              />
            </div>
          </div>
          <div>
            <h5>Lavador de rolaria</h5>
            <div className="mini-card">
              <img src={imagemLavador} alt="imagem de lavador de rolaria" />
            </div>
          </div>
          <div>
            <h5>Limpador</h5>
            <div className="mini-card">
              <img
                src={imagemLimpadorDeChapa}
                alt="imagem de limpador de chapas"
              />
            </div>
          </div>
          <div>
            <h5>Solução de fonte</h5>
            <div className="mini-card">
              <img
                src={imagemSolucaoDeFonte}
                alt="imagem de solução de fonte"
              />
            </div>
          </div>
          <div>
            <h5>Goma sintética</h5>
            <div className="mini-card">
              <img src={imagemGomaSintetica} alt="imagem goma sintética" />
            </div>
          </div>
          <div>
            <h5>Ácido Fosfórico</h5>
            <div className="mini-card">
              <img src={imagemAcidoFosforico} alt="imagem de ácido fosfórico" />
            </div>
          </div>
          <div>
            <h5>Revelador em pó</h5>
            <div className="mini-card">
              <img src={imagemReveladorEmPó} alt="imagem de revelador em pó" />
            </div>
          </div>
          <div>
            <h5>Pó anti-maculador</h5>
            <div className="mini-card">
              <img
                src={imagemPoAntiMaculador}
                alt="imagem de pó anti-maculador"
              />
            </div>
          </div>
          <div>
            <h5>Corretor de chapas</h5>
            <div className="mini-card">
              <img
                src={imagemCorretorDeChapa}
                alt="imagem de corretor de chapa"
              />
            </div>
          </div>
          <div>
            <h5>Cola de blocagem</h5>
            <div className="mini-card">
              <img
                src={imagemColaBlocagem}
                alt="imagem de cola para blocagem"
              />
            </div>
          </div>
          <div>
            <h5>Enegrecedor</h5>
            <div className="mini-card">
              <img
                src={imagemEnegrecedor}
                alt="imagem de enegrecedor em spray"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="column">
        <section className="plate-container">
          <h2>Chapas</h2>
          <div className="mini-card-container">
            <div>
              <h5>Chapas térmicas</h5>
              <div className="mini-card">
                <img src={imagemChapaCTP} alt="imagem de chapas de aluminio" />
              </div>
            </div>
            <div>
              <h5>diversos tamanhos</h5>
              <div className="mini-card">
                <img src={imagemChapas} alt="imagem de diversas chapas" />
              </div>
            </div>
          </div>
        </section>
        <section className="paints-container">
          <h2>Tintas pantone</h2>
          <div>
            <span>Vermelho Regal</span>
            <span>Azul Rei</span>
            <span>Verde Bandeira</span>
            <span>Branco Opaco</span>
            <span>entre outras</span>
          </div>
        </section>
      </div>
      <section className="others-container">
        <h2>Outros produtos</h2>
        <div className="mini-card-container">
          <div>
            <h5>Réguas</h5>
            <div className="mini-card">
              <img
                src={imagemReguaDeGuilhotina}
                alt="imagem de regua para guilhotina"
              />
            </div>
          </div>
          <div>
            <h5>Estopa</h5>
            <div className="mini-card">
              <img src={imagemEstopa} alt="imagem de estopa" />
            </div>
          </div>
          <div>
            <h5>Pralim</h5>
            <div className="mini-card">
              <img src={imagemPralim} alt="imagem de toalha pralim" />
            </div>
          </div>
          <div>
            <h5>Algodão</h5>
            <div className="mini-card">
              <img src={imagemAlgodao} alt="imagem de algodao" />
            </div>
          </div>
          <div>
            <h5>Arame Galvanizado</h5>
            <div className="mini-card">
              <img src={imagemArame} alt="imagem de arame galvanizado" />
            </div>
          </div>
          <div>
            <h5>Grampos</h5>
            <div className="mini-card">
              <img src={imagemGrampos} alt="imagem de grampos" />
            </div>
          </div>
          <div>
            <h5>Blanquetas</h5>
            <div className="mini-card">
              <img src={imagemBlanquetas} alt="imagem de blanquetas" />
            </div>
          </div>
          <div>
            <h5>Esponja litógráfica</h5>
            <div className="mini-card">
              <img src={imagemEsponja} alt="imagem de esponja litográfica" />
            </div>
          </div>
          <div>
            <h5>Moletom</h5>
            <div className="mini-card">
              <img src={imagemMoletom} alt="imagem de moletom" />
            </div>
          </div>
          <div>
            <h5>Filme para fotolito</h5>
            <div className="mini-card">
              <img
                src={imagemFilmeFotolito}
                alt="imagem de rolo de filme para fotolito"
              />
            </div>
          </div>
          <div>
            <h5>Laser filme</h5>
            <div className="mini-card">
              <img src={imagemLaserFilme} alt="imagem de laser filme" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProdutctsPage;
